import * as THREE from 'three';
//import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import {GLTF, GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader.js';
//import { OrbitControls } from 'three/addons/controls/OrbitControls.js';
import {OrbitControls} from 'three-stdlib';
//import { GLTF, GLTFLoader } from 'three-stdlib/loaders/GLTFLoader';
import {MeshBVH, acceleratedRaycast} from 'three-mesh-bvh';
//import * as Collections from 'typescript-collections';
//import * as Immutable from "immutable";

//import Api from './api/api';
//import Woman from '../assets/models/woman-norig-mirrorapplied.glb';
import Woman from '../assets/models/woman-norig-triangled-mirrored.glb';
//import SetOps from "./utils/SetOps";
//import {triangle9ByFaceIndex} from "./utils/Triangle9";
//import Interactor from "./wasmer/Interactor";
import WasmApiWorker from './workers/WasmApiWorker?worker';
//import {isUndefined} from "typescript-collections/dist/lib/util";
//import apiWorkerUrl from "./workers/apiWorker.ts?worker&url";
import AsyncWorkerQueue from './workers/AsyncWorkerQueue';


let scene: THREE.Scene;
let camera: THREE.PerspectiveCamera;
let renderer: THREE.WebGLRenderer;
let loader: GLTFLoader;
let controls: OrbitControls;
let geometry: THREE.BufferGeometry;
let wasmApiWorker: Worker;
let asyncWorkerQueue: AsyncWorkerQueue;

let isFullyInited: boolean = false;
let isWasmApiWorkerPreInited: boolean = false;
let renderInitEndReached: boolean = false;

let gltfLoaded: boolean = false;

let threeTriangles9: number[] = [];
let threeTrianglesIds: number[] = [];
let sentHover: boolean = false;
//let sentSwitchArea: boolean = false;
//let sentOnceAreaUp: boolean = false;

let highlighted: boolean = false;
let currentTriangleIndex: number = -1;

let resetAreaMedian: number[] = [0, 5, 0];
let resetAreaNormal: number[] = [0, 0, 1];

let currentAreaCentroid: number[] = resetAreaMedian;
let currentAreaNormal: number[] = resetAreaNormal;
let currentAreaID: number = 0;
let currentLeftSide: boolean = false;

let isCanvasHovered: boolean = false;
let bodyCanvas: HTMLCanvasElement;


function init(): void {
	//THREE.Object3D.DEFAULT_UP.set(0, 0, 1);

	// Set up the scene
	scene = new THREE.Scene();
	scene.background = new THREE.Color(0xaaaaaa);

	// Set up the camera
	camera = new THREE.PerspectiveCamera(60, window.innerWidth / window.innerHeight, 0.1, 1000);
	camera.up.set(0, 1, 0);  // Y-axis is up
	camera.position.set(0, 5, 12);
	camera.lookAt(0, 5, 0);

	// Set up the renderer
	//renderer = new THREE.WebGLRenderer({antialias: true});
	bodyCanvas = document.getElementById('three-body-canvas') as HTMLCanvasElement;
	renderer = new THREE.WebGLRenderer({antialias: true, canvas: bodyCanvas});
	renderer.setSize(window.innerWidth, window.innerHeight);
	//document.body.appendChild(renderer.domElement);
	document.getElementById('three-body-canvas-container')?.appendChild(renderer.domElement);
	bodyCanvas.addEventListener('mouseenter', () => isCanvasHovered = true);
	bodyCanvas.addEventListener('mouseleave', () => isCanvasHovered = false);

	// Load a GLB model
	loader = new GLTFLoader();
	loader.load(Woman, (gltf: GLTF): void => {
		const model: THREE.Group<THREE.Object3DEventMap> = gltf.scene;

		// Traverse the model and set up BVH for each meshIterate over all faces, get indices of faces, coordinates of their vertices (
		model.traverse((child: THREE.Object3D<THREE.Object3DEventMap>): void => {
			if((child as THREE.Mesh).isMesh && child.name === 'Woman_Body') {
				const mesh: THREE.Mesh<THREE.BufferGeometry, THREE.Material | THREE.Material[], THREE.Object3DEventMap> = child as THREE.Mesh;
				mesh.geometry.boundsTree = new MeshBVH(mesh.geometry);
				mesh.raycast = acceleratedRaycast;
				//console.log('Mesh:', mesh.geometry.boundsTree.geometry.attributes.position.array.length / 3);
				//console.log('Mesh:', mesh.geometry.attributes.position.array.length / 3);

				// Enable vertex colors
				const bufferGeometry: THREE.BufferGeometry<THREE.NormalBufferAttributes> = mesh.geometry as THREE.BufferGeometry;
				geometry = mesh.geometry as THREE.BufferGeometry;
				const position: THREE.BufferAttribute | THREE.InterleavedBufferAttribute = bufferGeometry.attributes.position;
				const colors: Float32Array = new Float32Array(position.count * 3); // 3 components per vertex
				bufferGeometry.setAttribute('color', new THREE.BufferAttribute(colors, 3));
				mesh.material = new THREE.MeshStandardMaterial({
					vertexColors: true,
					side: THREE.DoubleSide
				});

				for(let i = 0; i < position.count; i++)
					(mesh.geometry.attributes.color as THREE.BufferAttribute).setXYZ(i, 1, 1, 1); // White color

				//Interactor.runGo(mesh.geometry);


				// заполняем в удобное хранение треугольники
				if(geometry.isBufferGeometry) {
					// Get the position attribute, which contains the vertices
					const position = geometry.getAttribute('position');
					const indices = geometry.index;

					// Check if indices are available
					if(indices) {
						console.log("indices are available");
						for(let i = 0; i < indices.count; i += 3) {
							const a = indices.getX(i);
							const b = indices.getX(i + 1);
							const c = indices.getX(i + 2);

							// Get the vertices for the current face
							const vA = new THREE.Vector3().fromBufferAttribute(position, a);
							const vB = new THREE.Vector3().fromBufferAttribute(position, b);
							const vC = new THREE.Vector3().fromBufferAttribute(position, c);

							threeTrianglesIds.push(i / 3);
							threeTriangles9.push(vA.x, vA.y, vA.z, vB.x, vB.y, vB.z, vC.x, vC.y, vC.z);
							// Log or store the data as needed
							//console.log(`Face ${i / 3}: Indices = [${a}, ${b}, ${c}]`);
							//console.log(`Vertices = [(${vA.x}, ${vA.y}, ${vA.z}), (${vB.x}, ${vB.y}, ${vB.z}), (${vC.x}, ${vC.y}, ${vC.z})]`);
						}
					} else {
						console.log("indices are not available");
						// If there are no indices, process the vertices directly
						for(let i = 0; i < position.count; i += 3) {
							const vA: THREE.Vector3 = new THREE.Vector3().fromBufferAttribute(position, i);
							const vB: THREE.Vector3 = new THREE.Vector3().fromBufferAttribute(position, i + 1);
							const vC: THREE.Vector3 = new THREE.Vector3().fromBufferAttribute(position, i + 2);

							threeTrianglesIds.push(i / 3);
							threeTriangles9.push(vA.x, vA.y, vA.z, vB.x, vB.y, vB.z, vC.x, vC.y, vC.z);
							// Log or store the data as needed
							//console.log(`Face ${i / 3}: Indices = [${i}, ${i + 1}, ${i + 2}]`);
							//console.log(`Vertices = [(${vA.x}, ${vA.y}, ${vA.z}), (${vB.x}, ${vB.y}, ${vB.z}), (${vC.x}, ${vC.y}, ${vC.z})]`);
						}
					}
				}

				if(isWasmApiWorkerPreInited && renderInitEndReached)
					sendThreeTrianglesIfNotYet();
				gltfLoaded = true;
			}
		});

		scene.add(model);
	}, undefined, (error: unknown): void => {
		console.error(error);
	});

	// Set up controls to rotate the model with the mouse
	controls = new OrbitControls(camera, renderer.domElement);
	controls.enableDamping = true; // Optional
	controls.dampingFactor = 0.05;
	controls.target.set(0, 5, 0);
	// Мувы:
	//controls.enableRotate = false;
	//controls.enablePan = false;
	//controls.enableZoom = false;

	// Lighting
	const hemiLight: THREE.HemisphereLight = new THREE.HemisphereLight(0xffffff, 0x444444);
	hemiLight.position.set(0, 20, 0);
	scene.add(hemiLight);

	const dirLight: THREE.DirectionalLight = new THREE.DirectionalLight(0xffffff);
	dirLight.position.set(0, 20, 10);
	scene.add(dirLight);

	// The X axis is red. The Y axis is green. The Z axis is blue.
	//const axesHelper: THREE.AxesHelper = new THREE.AxesHelper(5);
	//scene.add(axesHelper);

	/*let womanHeightCM: number = 163.7;
	let modelHeight: number = 8.4823513031005859;

	let gridHeightCM: number = 200;
	let gridCoefficient: number = modelHeight / womanHeightCM;
	const gridSize: number = gridHeightCM * gridCoefficient;
	const gridDivisions: number = 200;
	const gridHelper: THREE.GridHelper = new THREE.GridHelper(gridSize, gridDivisions, 0x888888, 0x888888);
	gridHelper.rotation.x = Math.PI / 2;
	gridHelper.position.y = gridSize / 2;
	scene.add(gridHelper);*/

	// Resize handler
	window.addEventListener('resize', onWindowResize, false);
	//bodyCanvas.addEventListener('resize', onBodyCanvasResize, false);

	//window.addEventListener('mousemove', onMouseMove, false);
	// bodyCanvas.addEventListener('mousemove', onMouseMove, false);
	//bodyCanvas.addEventListener('click', onMouseMove, false);


	if(isWasmApiWorkerPreInited && gltfLoaded)
		sendThreeTrianglesIfNotYet();
	renderInitEndReached = true;
}

let mouseDownPosition: THREE.Vector2 = new THREE.Vector2(-1, -1);
let isPressedMouseWalking: boolean = false;
let trianglesSelectedByClick: Set<number> = new Set<number>();
let isSelectedByClickHighlighted: boolean = true;
let areaSelectedByClickName: string = "";

function isMouseDown(): boolean {
	return mouseDownPosition.x !== -1 && mouseDownPosition.y !== -1;
}

const raycaster: THREE.Raycaster = new THREE.Raycaster();
const mouse: THREE.Vector2 = new THREE.Vector2();

function onMouseDown(event: MouseEvent): void {
	if(!isFullyInited || !isCanvasHovered)
		return;

	//mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
	//mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

	mouseDownPosition.x = (event.clientX / bodyCanvas.width) * 2 - 1;
	mouseDownPosition.y = -(event.clientY / bodyCanvas.height) * 2 + 1;
}

function onMouseUp(event: MouseEvent): void {
	if(!isFullyInited)
		return;

	//mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
	//mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

	if(isPressedMouseWalking)
		isPressedMouseWalking = false;
	else
		onMouseMove(event, true);

	mouseDownPosition.x = -1;
	mouseDownPosition.y = -1;
}

function onMouseMove(event: MouseEvent, useMouseDownPosition: boolean = false): void {
	if(!isFullyInited || isPressedMouseWalking)
		return;

	// Convert mouse position to normalized device coordinates (NDC)
	mouse.x = (event.clientX / bodyCanvas.width) * 2 - 1; // window.innerWidth
	mouse.y = -(event.clientY / bodyCanvas.height) * 2 + 1; // window.innerHeight

	if(isMouseDown() && 1e-6 < Math.abs(mouseDownPosition.x - mouse.x) ** 2 + Math.abs(mouseDownPosition.y - mouse.y) ** 2) {
		isPressedMouseWalking = true;
		//mouseDownPosition.x = -1;
		//mouseDownPosition.y = -1;
		return;
	}

	if((sentHover && event.type !== 'mouseup') || !isCanvasHovered)
		return;

	// Convert mouse position to normalized device coordinates (NDC)
	//mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
	//mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

	// Update the raycaster with the camera and mouse position
	raycaster.setFromCamera(useMouseDownPosition ? mouseDownPosition : mouse, camera);

	// Check for intersections with the model
	const intersects: Array<THREE.Intersection<THREE.Object3D>> = raycaster.intersectObjects(scene.children, true);

	if(event.type === 'mouseup') {
		isSelectedByClickHighlighted = false;
		trianglesSelectedByClick.clear();
		areaSelectedByClickName = "";
	}

	/*if(intersects.length != 0) {
		console.log('Intersects:');
		for(let i = 0; i < intersects.length; i++) {
			if(intersects[i].object.name === 'Woman_Body') {
				console.log(intersects[i]);
			}
		}
		console.log('End intersects');
	}*/

	// let bodyIntersection: THREE.Intersection<THREE.Object3D> | null = null;
	for(let i = 0; i < intersects.length; i++)
		if(intersects[i].object.name === 'Woman_Body') {
			sentHover = true;
			//currentTriangleIndex = intersects[i].faceIndex as number;
			//wasmApiWorker.postMessage({name: 'trianglesByThreeTriangleID', triangleID: currentTriangleIndex = intersects[i].faceIndex as number});
			asyncWorkerQueue.postMessage({
				task: 'trianglesByThreeTriangleID',
				triangleID: currentTriangleIndex = intersects[i].faceIndex as number,
				wasClicked: event.type === 'mouseup'
			}).then((result: object): void => {
				if(result['wasClicked']) {
					trianglesSelectedByClick = new Set<number>(result['trianglesIndices']);
					areaSelectedByClickName = result['areaName'];
				}
				highlightTriangles(result['trianglesIndices'], result['areaName']);
				if(result['wasClicked'])
					void refocusCamera(currentAreaCentroid = result['centroid'], currentAreaNormal = result['normal'], currentAreaID = result['areaID'], currentLeftSide = result['leftSide']);
				sentHover = false;
			});
			return;
			// bodyIntersection = intersects[i];
			// break;
		}

	//if(event.type === 'mouseup')
	//	trianglesSelectedByClick.clear();
	highlightTriangles();

	// if(bodyIntersection === null)
	// 	return highlightTriangles();

	//const nearestIntersection: THREE.Intersection<THREE.Object3D> = intersects[0];

	//const intersectedFace: THREE.Face | null | undefined = nearestIntersection.face;

	/*console.log('---START INTERSECT---')
	console.log('Nearest intersected face:', intersectedFace);
	console.log('nearestIntersection:', nearestIntersection);
	console.log('Intersection point:', nearestIntersection.point);
	console.log('Object:', nearestIntersection.object);
	console.log('Face index:', nearestIntersection.faceIndex);
	console.log('Face coords:', triangle9ByFaceIndex(nearestIntersection.faceIndex as number, geometry as THREE.BufferGeometry));
	console.log('---END INTERSECT---')*/

	//highlightIntersectedArea(nearestIntersection);

	// sentHover = true;
	//highlighted = true;
	//wasmApiWorker.postMessage({name: 'trianglesByTriangle9', triangle9: triangle9ByFaceIndex(nearestIntersection.faceIndex as number, geometry)});
	// wasmApiWorker.postMessage({name: 'trianglesByThreeTriangleID', triangleID: bodyIntersection.faceIndex as number});
}

const areaInfoBlock: HTMLDivElement = document.getElementById('area-name') as HTMLDivElement;

function capitalizeFirstLetter(str: string): string {
	return str.charAt(0).toUpperCase() + str.slice(1);
}

function highlightTriangles(trianglesIndices: number[] = [], areaName: string = ""): void {
	if(!highlighted && trianglesIndices.length === 0 && isSelectedByClickHighlighted)
		return;

	scene.traverse((child: THREE.Object3D<THREE.Object3DEventMap>): void => {
		if((child as THREE.Mesh).isMesh && child.name === 'Woman_Body') {
			const mesh: THREE.Mesh<THREE.BufferGeometry, THREE.Material | THREE.Material[], THREE.Object3DEventMap> = child as THREE.Mesh;
			const geometry: THREE.BufferGeometry<THREE.NormalBufferAttributes> = mesh.geometry as THREE.BufferGeometry;
			//const position: THREE.BufferAttribute = geometry.attributes.position as THREE.BufferAttribute;

			const ind: THREE.BufferAttribute | null = geometry.getIndex();

			if(ind === null)
				return;

			const colors: THREE.BufferAttribute = geometry.attributes.color as THREE.BufferAttribute;

			for(let i = 0; i < colors.count; i++)
				colors.setXYZ(i, 1, 1, 1);

			const highlightTriangle = (triangleIndex: number): void => {
				/*const a = ind.getX(triangleIndex * 3);
				const b = ind.getX(triangleIndex * 3 + 1);
				const c = ind.getX(triangleIndex * 3 + 2);

				let vertexIndices: number[] = [a, b, c];*/

				let vertexIndices: number[] = Array.from({length: 3}, (_, i) => ind.getX(triangleIndex * 3 + i));
				for(const vertexIndex of vertexIndices)
					colors.setXYZ(vertexIndex, 1, 0, 0); // Set color to Red
			}

			for(const triangleIndex of trianglesSelectedByClick)
				highlightTriangle(triangleIndex);

			for(const triangleIndex of trianglesIndices)
				highlightTriangle(triangleIndex);

			colors.needsUpdate = true;

			//if(!(highlighted = trianglesIndices.length !== 0))
			highlighted = trianglesIndices.length !== 0;
			if(!highlighted)
				currentTriangleIndex = -1;

			isSelectedByClickHighlighted = true;
		}
	});

	if(areaName.length === 0) {
		if(areaSelectedByClickName.length === 0)
			areaInfoBlock.innerText = "Зона не выбрана";
		else
			areaInfoBlock.innerText = capitalizeFirstLetter(areaSelectedByClickName);
	} else
		areaInfoBlock.innerText = capitalizeFirstLetter(areaName);
}

function onWindowResize(): void {
	camera.aspect = window.innerWidth / window.innerHeight;
	camera.updateProjectionMatrix();
	renderer.setSize(window.innerWidth, window.innerHeight);
}

/*function onBodyCanvasResize(): void {
	camera.aspect = bodyCanvas.width / bodyCanvas.height;
	camera.updateProjectionMatrix();
	renderer.setSize(bodyCanvas.width, bodyCanvas.height);
}*/

function cameraMoveAlphaByLinearAlpha(x: number): number {
	return x;
	// return ...;
	/* It has such properties:
	1. f(0) = 0
	2. f(1) = 1
	3. f'(0) = 1
	4. f'(1) = 2
	5. f'(x) > 0 on x \in (0, 1]
	6. f''(x) > 0 on x \in (0, 1] */
}

function animate(): void {
	requestAnimationFrame(animate);

	if(isCameraMoving) {
		if(lerpStepsDone < lerpSteps) {
			const lerpAlpha: number = ((lerpStepsDone + 1) / lerpSteps);
			let nowTime: number = performance.now();
			if(moveTime * lerpAlpha < nowTime - moveStartTime) {
				const moveAlpha: number = cameraMoveAlphaByLinearAlpha(lerpAlpha);
				// Lerp camera position
				camera.position.lerp(endCameraPosition, moveAlpha);
				camera.updateProjectionMatrix();

				// Lerp controls target position
				controls.target.lerp(endTargetPosition, moveAlpha);
				//controls.update();

				lerpStepsDone++;
			}
		} else
			controls.enabled = !(isCameraMoving = false);
	}

	// Update controls
	controls.update();

	renderer.render(scene, camera);
}

function sendThreeTrianglesIfNotYet(): void {
	if(sendThreeTrianglesIfNotYet['sent'] === true)
		return;
	sendThreeTrianglesIfNotYet['sent'] = true;

	asyncWorkerQueue.postMessage({task: 'threeTriangles', triangles9: threeTriangles9, indices: threeTrianglesIds}).then((result: object): void => {
		console.log('Three triangles returned:', result);

		upperAreaButton.addEventListener('click', (): void => {
			// TO-DO: sentHover = true; ?
			switchArea(true);
		});

		lowerAreaButton.addEventListener('click', (): void => {
			// TO-DO: sentHover = true; ?
			switchArea(false);
		});

		onceUpButton.addEventListener('click', (): void => {
			onceAreaUp();
		});

		refocusCameraButton.addEventListener('click', (): void => {
			void refocusCamera(currentAreaCentroid, currentAreaNormal, currentAreaID, currentLeftSide);
		});

		resetCameraButton.addEventListener('click', (): void => {
			void refocusCamera(currentAreaCentroid = resetAreaMedian, currentAreaNormal = resetAreaNormal, currentAreaID = 0, currentLeftSide = false);
		});

		///bodyCanvas.addEventListener('click', onMouseMove, false);
		bodyCanvas.addEventListener('mousemove', onMouseMove, false);
		bodyCanvas.addEventListener('mousedown', onMouseDown, false);
		bodyCanvas.addEventListener('mouseup', onMouseUp, false);

		/*resetCameraButton.addEventListener('click', () => {
			camera.position.set(0, 1, 2);
			controls.update();
		});*/

		levelInfoBlock.removeAttribute('hidden');
		areaInfoBlock.innerText = "Зона не выбрана";

		onWindowResize();

		isFullyInited = true;
		let now: number = performance.now(); // TO-DO: try console.time console.timeEnd
		let tookTime: number = (now - startOfMain) * 1e-3;
		let tookSincePre: number = (now - preInitEnd) * 1e-3;
		//alert(`Готово, ${tookTime} секунд, since pre init: ${tookSincePre} seconds`);
		console.log(`WE ARE FULLY INITED! ${tookTime} seconds, ${tookSincePre} seconds`);
	});
	// freeing memory:
	threeTriangles9.length = 0;
	threeTrianglesIds.length = 0;
}

function switchArea(goingUp: boolean): void { // up - true, down - false
	// if(isFullyInited) maybe better is like this?
	//wasmApiWorker.postMessage({name: 'switchArea', direction: upOrDown, threeTriangleIndex: currentTriangleIndex});
	if(isFullyInited && !sentHover) {
		sentHover = true;
		void asyncWorkerQueue.postMessage({task: 'switchArea', directionUp: goingUp, threeTriangleIndex: highlighted ? currentTriangleIndex : -1}).then((result: object): void => {
			if(result['trianglesIndices'].length != 0) { // up
				highlightTriangles(result['trianglesIndices'], result['areaName']);
				void refocusCamera(currentAreaCentroid = result['centroid'], currentAreaNormal = result['normal'], currentAreaID = result['areaID'], currentLeftSide = result['leftSide']);
			}
			currentLevelBlock.innerText = result['level'].toString();
			sentHover = false;
		});
	}
}

function onceAreaUp(): void {
	if(isFullyInited && currentTriangleIndex !== -1 && highlighted && !sentHover) {
		sentHover = true;
		void asyncWorkerQueue.postMessage({task: "onceAreaUp", threeTriangleIndex: currentTriangleIndex}).then((result: object): void => {
			if(result['trianglesIndices'].length === 0)
				return;
			highlightTriangles(result['trianglesIndices'], result['areaName']);
			void refocusCamera(currentAreaCentroid = result['centroid'], currentAreaNormal = result['normal'], currentAreaID = result['areaID'], currentLeftSide = result['leftSide']);
			sentHover = false;
		});
	}
}

function handleKeyDown(event: KeyboardEvent): void { // Define a function that handles the keydown of ANY keyboard key event
	if(isFullyInited) {
		if(event.key == "ArrowUp" || event.key == "ArrowDown")
			switchArea(event.key === "ArrowUp");
		else if(event.key == "ArrowLeft")
			onceAreaUp();
	}
}

let isCameraMoving: boolean = false;
//let startCameraPosition: THREE.Vector3;
let endCameraPosition: THREE.Vector3;
//let startTargetPosition: THREE.Vector3;
let endTargetPosition: THREE.Vector3;
//let lerpAlpha: number;  // Alpha value for lerping between 0 and 1
//const lerpSpeed: number = 0.025;  // Adjust this value for speed of interpolation (smaller values = slower)
let lerpStepsDone: number;
const lerpSteps: number = 40;
const moveTime: number = 250; // time in ms
let moveStartTime: number;

async function refocusCamera(to: number[], antiDirection: number[], areaID: number, leftSide: boolean): Promise<void> {
	if(!isFullyInited)
		return;

	if(to === undefined || to.length !== 3 || to == resetAreaMedian || areaID == 0)
		to = resetAreaMedian;
	//camera.position.set(0, 5, 12);
	//camera.lookAt(0, 5, 0);
	if(antiDirection === undefined || antiDirection.length !== 3 || antiDirection == resetAreaNormal || areaID == 0)
		antiDirection = resetAreaNormal;

	console.log('Normal:', antiDirection);
	console.log('Centroid:', to);
	console.log('------')

	if(to === resetAreaMedian && antiDirection === resetAreaNormal) {
		camera.position.set(0, 5, 12);
		camera.lookAt(0, 5, 0);
		return;
	}
	//let direction: number[] = [-antiDirection[0], -antiDirection[1], -antiDirection[2]];

	const pointTo: THREE.Vector3 = new THREE.Vector3(to[0], to[1], to[2]);
	const dir: THREE.Vector3 = new THREE.Vector3(antiDirection[0], antiDirection[1], antiDirection[2]);
	console.log("Cur dir len:", dir.length());

	//controls.enableDamping = false;
	//controls.enabled = false;

	//camera.updateProjectionMatrix();
	//camera.updateMatrixWorld();
	//camera.updateMatrix();

	const distance: number = (await asyncWorkerQueue.postMessage({
		task: 'getDistanceToCamera',
		"areaID": areaID,
		isLeftSide: leftSide,
		target: to,
		normal: antiDirection,
		fov: camera.fov,
		aspect: camera.aspect,
		near: camera.near,
		far: camera.far
	}))['distance'];

	//controls.enableDamping = true;
	//controls.enabled = true;

	if(leftSide) {
		dir.setX(-dir.x);
		pointTo.setX(-pointTo.x);
	}

	dir.multiplyScalar(distance);
	const pointFrom: THREE.Vector3 = pointTo.clone();
	pointFrom.add(dir);

	{
		const lineGeometry: THREE.BufferGeometry = new THREE.BufferGeometry().setFromPoints([pointFrom, pointTo]);
		const lineMaterial: THREE.LineBasicMaterial = new THREE.LineBasicMaterial({color: 0x0000ff}); // Blue line
		const line: THREE.Line = new THREE.Line(lineGeometry, lineMaterial);
		scene.add(line);

		const pointGeometry1: THREE.BufferGeometry = new THREE.BufferGeometry().setFromPoints([pointTo]);
		// Create material for the point (PointsMaterial allows control of size)
		const pointMaterial1: THREE.PointsMaterial = new THREE.PointsMaterial({
			color: 0x00ff00,   // Green color
			size: 0.1,         // Size of the point (larger size for a thick dot)
		});
		const point1: THREE.Points = new THREE.Points(pointGeometry1, pointMaterial1);
		scene.add(point1);

		const pointGeometry2: THREE.BufferGeometry = new THREE.BufferGeometry().setFromPoints([pointFrom]);
		// Create material for the point (PointsMaterial allows control of size)
		const pointMaterial2: THREE.PointsMaterial = new THREE.PointsMaterial({
			color: 0xffff00,   // Yellow color
			size: 0.1,         // Size of the point (larger size for a thick dot)
		});
		const point2: THREE.Points = new THREE.Points(pointGeometry2, pointMaterial2);
		scene.add(point2);
	}

	console.log('Dist:', distance);
	console.log('Resulted from:', pointFrom);
	console.log('Resulted to:', pointTo);

	/* no smooth:
	camera.position.set(pointFrom.x, pointFrom.y, pointFrom.z);

	camera.updateProjectionMatrix();

	controls.enableDamping = false;  // Disable damping temporarily if needed
	controls.target.set(pointTo.x, pointTo.y, pointTo.z);
	controls.update();
	controls.enableDamping = true;
	 */

	isCameraMoving = !(controls.enabled = false);
	//startCameraPosition = new THREE.Vector3(camera.position.x, camera.position.y, camera.position.z);
	endCameraPosition = pointFrom.clone();
	//startTargetPosition = new THREE.Vector3(controls.target.x, controls.target.y, controls.target.z);
	endTargetPosition = pointTo.clone();
	lerpStepsDone = 0;
	moveStartTime = performance.now();

	/*camera.position.set(pointFrom.x, pointFrom.y, pointFrom.z);
	camera.updateProjectionMatrix();
	camera.updateMatrixWorld();
	camera.lookAt(pointTo);
	camera.updateProjectionMatrix();
	camera.updateMatrixWorld();
	controls.update();*/
}

document.addEventListener("keydown", handleKeyDown);

let startOfMain: number;
let preInitEnd: number;

const upperAreaButton: HTMLDivElement = document.getElementById('upper-area-button') as HTMLDivElement;
const lowerAreaButton: HTMLDivElement = document.getElementById('lower-area-button') as HTMLDivElement;
const resetCameraButton: HTMLDivElement = document.getElementById('reset-camera-button') as HTMLDivElement;
const refocusCameraButton: HTMLDivElement = document.getElementById('focus-camera-button') as HTMLDivElement;
const onceUpButton: HTMLDivElement = document.getElementById('once-up-button') as HTMLDivElement;

const levelInfoBlock: HTMLSpanElement = document.getElementById('level-info') as HTMLSpanElement;
const currentLevelBlock: HTMLSpanElement = document.getElementById('cur-level') as HTMLSpanElement;
const maxLevelBlock: HTMLSpanElement = document.getElementById('max-level') as HTMLSpanElement;

function main(): void {
	startOfMain = performance.now();
	wasmApiWorker = new WasmApiWorker();
	asyncWorkerQueue = new AsyncWorkerQueue(wasmApiWorker, 4);

	asyncWorkerQueue.postMessage({task: 'initWasm'}).then((result: object): void => {
		maxLevelBlock.innerText = result['maxLevel'].toString();
		currentLevelBlock.innerText = result['maxLevel'].toString();

		if(renderInitEndReached && gltfLoaded)
			sendThreeTrianglesIfNotYet();

		isWasmApiWorkerPreInited = true;

		preInitEnd = performance.now();
		console.log(`wasm api worker is pre-inited! ${(preInitEnd - startOfMain) * 1e-3} seconds`);
	});


	init();
	animate();
}

main();
