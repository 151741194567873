import {isUndefined} from "typescript-collections/dist/lib/util";

export default class AsyncWorkerQueue {
	private worker: Worker;
	private taskQueue: Array<() => void> = [];
	private pendingTasks: number = 0;
	private concurrencyLimit: number;
	private messageId: number = 0;
	private pendingPromises: Map<number, { resolve: (value: object) => void; reject: (reason?: any) => void }> = new Map();

	public handledTasks: Map<string, { resolve: (value: object) => void; reject: (reason?: any) => void }> = new Map(); // filled by user with taskName: {resolve, reject}

	public constructor(worker: Worker, concurrencyLimit: number = 4) {
		this.worker = worker;
		this.concurrencyLimit = concurrencyLimit;

		this.worker.addEventListener('message', (event: MessageEvent<any>): void => {
			const {id, error, ...result} = event.data;

			let handler: { resolve: (value: any) => void; reject: (reason?: any) => void } | undefined = this.pendingPromises.get(id);
			if(handler) {
				if(error)
					handler.reject({"error": error});
				else
					handler.resolve(result);
				this.pendingPromises.delete(id); // Clean up the promise
			}
			this.pendingTasks--;
			this.processQueue();  // Continue processing queued tasks
		});

		/*this.worker.onmessage = (event: MessageEvent<any>): void => {
			const {id, error, ...result} = event.data;

			let handler: { resolve: (value: any) => void; reject: (reason?: any) => void } | undefined = this.pendingPromises.get(id);
			if(handler) {
				if(error)
					handler.reject({"error": error});
				else
					handler.resolve(result);
				this.pendingPromises.delete(id); // Clean up the promise
			}
			this.pendingTasks--;
			this.processQueue();  // Continue processing queued tasks
		};*/

		// мы id не видим, поэтому хер захендлишь (ну можно в worker'е все в try-catch обернуть и возвращать error с id)
		/*this.worker.onerror = (error: ErrorEvent): void => {
			console.error('Worker error:', error); // Handle worker errors globally

			/// Loop through pending promises and reject them (since worker encountered an error)
			for(const [id, {reject}] of this.pendingPromises) {
				reject(new Error(`Worker failed with error: ${error.message}`));
				this.pendingPromises.delete(id);
			}
			this.pendingTasks = 0; // Reset the count of pending tasks/
		};*/

		this.worker.addEventListener('error', (error: ErrorEvent): void => {
			console.error('Worker error:', error); // Handle worker errors globally
		});
	}

	public async postMessage(message: any): Promise<object> {
		return new Promise<object>((resolve: (value: object) => void, reject: (reason?: any) => void): void => {
			const id: number = this.messageId++;
			this.pendingPromises.set(id, {resolve, reject});
			this.taskQueue.push((): void => this.worker.postMessage({id, ...message}));
			this.processQueue(); // Attempt to process the queue
		});
	}

	public async postTask(message: any): Promise<void> {
		if(!message.task)
			throw new Error('Task name is not provided');
		let handler: { resolve: (value: any) => void; reject: (reason?: any) => void } | undefined = this.handledTasks.get(message.task);
		if(isUndefined(handler))
			throw new Error(`Task ${message.task} is not handled`);
		return this.postMessage(message).then(handler.resolve).catch(handler.reject);
	}

	private processQueue(): void {
		while(0 < this.taskQueue.length && this.pendingTasks < this.concurrencyLimit) {
			const task: (() => void) | undefined = this.taskQueue.shift();
			if(task) {
				this.pendingTasks++;
				task(); // Execute task by posting a message to the worker
			}
		}
	}

	public terminate(): void {
		this.worker.terminate();
	}
}
